<template>
  <thead>
    <tr class="is-centered">
      <OrderingToggle
        field="transactionId"
      >
        Transaction ID
      </OrderingToggle>
      <OrderingToggle
        field="email"
      >
        Email
      </OrderingToggle>
      <OrderingToggle
        field="date"
      >
        Creation Date
      </OrderingToggle>
      <OrderingToggle
        field="settled_amount"
      >
        Settled Amount
      </OrderingToggle>
      <OrderingToggle
        field="firstName"
      >
        First Name
      </OrderingToggle>
      <OrderingToggle
        field="surname"
      >
        Surname
      </OrderingToggle>
      <OrderingToggle
        field="senderName"
      >
        Sender Name
      </OrderingToggle>
      <OrderingToggle
        field="bankName"
      >
        Bank Name
      </OrderingToggle>
      <OrderingToggle
        field="bankCode"
      >
        Bank Code
      </OrderingToggle>
      <OrderingToggle
        field="branchCode"
      >
        Branch Code
      </OrderingToggle>
      <OrderingToggle
        field="accountNumber"
      >
        Account Number
      </OrderingToggle>
      <OrderingToggle
        field="companyName"
      >
        Company Name
      </OrderingToggle>
      <OrderingToggle
        field="nonce"
      >
        Nonce
      </OrderingToggle>
      <OrderingToggle
        field="merchantId"
      >
        Merchant
      </OrderingToggle>
      <OrderingToggle
        field="transactionRequestDate"
      >
        Receipt Date
      </OrderingToggle>
      <OrderingToggle
        field="status"
      >
        Status
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayCreation"
        field="creation"
      >
        Creation
      </OrderingToggle>
      <OrderingToggle
        field="userAgent"
      >
        Client ID
      </OrderingToggle>
      <OrderingToggle
        field="fingerprint"
      >
        Fingerprint
      </OrderingToggle>
      <OrderingToggle
        field="ipAddress"
      >
        User IP
      </OrderingToggle>
      <OrderingToggle
        field="processingFee"
      >
        Processing Fee
      </OrderingToggle>
      <OrderingToggle
        field="transactionFee"
      >
        Transaction Fee
      </OrderingToggle>
      <OrderingToggle
        field="authorizedAmount"
      >
        Authorized Amount
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplaySourceBankName"
        field="sourceBankName"
      >
        Source Bank Name
      </OrderingToggle>
      <th>Edit</th>
      <th>Receive</th>
      <OrderingToggle
        v-if="shouldDisplayReceivedBy"
        field="receivedBy"
      >
        Operator
      </OrderingToggle>
    </tr>
  </thead>
</template>

<script>
import OrderingToggle from './OrderingToggle.vue';
import initializeFlagsmith from '../flagsmith';

export default {
    name: 'OrderableTableHeader',

    components: {
        OrderingToggle,
    },

    async setup() {
        const flagsmith = await initializeFlagsmith();
        const shouldDisplaySourceBankName = flagsmith.hasFeature('btb_source_bank');
        const shouldDisplayReceivedBy = flagsmith.hasFeature('btb_admin_received_by_column');
        const shouldDisplayCreation = flagsmith.hasFeature('creation_status_btb');

        return {
            shouldDisplaySourceBankName,
            shouldDisplayReceivedBy,
            shouldDisplayCreation,
        };
    },
};
</script>
